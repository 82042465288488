import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import {
  IActionResult,
  IAvailableAzureVmValues,
  IDefaultAzureVmValues,
  IServiceViewModel,
  ServerType,
} from 'types';
import { Modal, Tab, Tabs } from 'react-bootstrap';

import { AnyAction } from 'redux';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import ModalFormFooter from 'components/common/modalFormFooter';
import TemplateSelector from './templateSelector';
import { ThunkDispatch } from 'redux-thunk';
import VmTypeSelector from './vmTypeSelector';
import { connect } from 'react-redux';
import { createService } from 'slices/serviceSlice';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';
import PolicySelector from './policySelector';
import selectComponent from 'components/common/formComponents/selectComponent';

enum ConfigurationType {
  POLICY = 'policy',
  TEMPLATE = 'template',
}

interface IState {
  selectedTab: number;
  selectedConfigType: string;
}

interface IParams {
  groupId: string;
}

interface IOwnProps {
  data: IDefaultAzureVmValues;
  params: IParams;
}

interface IStateProps {
  createResult: IActionResult<boolean>;
  availableVmValuesResult: IActionResult<IAvailableAzureVmValues>;
}

interface IDispatchProps {
  createService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  description: string;
  ipAddress: string;
  serviceName: string;
  sshUser: string;
  sshPassword: string;
  templateName: string;
  policyId: string;
  roleSize: string;
  hostName: string;
  vmOsPublisher: string;
  vmOsOffer: string;
  vmOsSku: string;
}

export class CreateVmModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>,
  IState
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedConfigType: ConfigurationType.POLICY,
    };
    this.save = this.save.bind(this);
    this.tabSelected = this.tabSelected.bind(this);
  }

  public render() {
    const {
      handleSubmit,
      pristine,
      createResult,
      availableVmValuesResult,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Tabs
            defaultActiveKey={0}
            id="newVmCreationWayTab"
            onSelect={this.tabSelected}
          >
            <Tab title="Provision" eventKey="0">
              <h4>Provision new service</h4>
              <Field
                name="serviceName"
                component={inputComponent}
                type="text"
                placeholder="Please enter service name"
                label="Service Name"
                validate={
                  this.state.selectedTab === 0
                    ? formValidators.required
                    : undefined
                }
              />
              <Field
                name="sshUser"
                component={inputComponent}
                type="text"
                label="SSH Admin User"
                validate={
                  this.state.selectedTab === 0
                    ? formValidators.required
                    : undefined
                }
              />
              <Field
                name="sshPassword"
                component={inputComponent}
                type="text"
                placeholder="Please enter a password"
                label="SSH Admin Password"
                validate={
                  this.state.selectedTab === 0
                    ? [formValidators.required, formValidators.minLength(8)]
                    : undefined
                }
              />
              <Field
                name="configurationType"
                component={selectComponent}
                label="Configuration Type"
                value={this.state.selectedConfigType}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  this.setState({ selectedConfigType: event.target.value })
                }
              >
                <option value={ConfigurationType.POLICY}>Policy</option>
                <option value={ConfigurationType.TEMPLATE}>Template</option>
              </Field>
              {this.state.selectedConfigType === ConfigurationType.TEMPLATE ? (
                <LoaderWithParams
                  component={TemplateSelector}
                  actionresult={availableVmValuesResult}
                  params={{
                    isRequired: this.state.selectedTab === 0,
                    disabled: false,
                  }}
                />
              ) : null}
              {this.state.selectedConfigType === ConfigurationType.POLICY ? (
                <LoaderWithParams
                  component={PolicySelector}
                  actionresult={availableVmValuesResult}
                  params={{
                    isRequired: this.state.selectedTab === 0,
                    disabled: false,
                  }}
                />
              ) : null}
              <LoaderWithParams
                component={VmTypeSelector}
                actionresult={availableVmValuesResult}
                params={{ isRequired: this.state.selectedTab === 0 }}
              />
              <Field
                name="hostName"
                component={inputComponent}
                type="text"
                placeholder="Please enter a host name"
                label="Host Name"
                validate={
                  this.state.selectedTab === 0
                    ? [
                        formValidators.required,
                        formValidators.maxLength(80),
                        formValidators.regex(
                          new RegExp('^[a-zA-Z0-9][\\w.-]*[\\w]$'),
                          "It must begin with a word character, and it must end with a word character or with '_'. The name may contain word characters or '.', '-', '_'."
                        ),
                      ]
                    : undefined
                }
              />
              <Field
                name="vmOsPublisher"
                component={inputComponent}
                type="text"
                label="OS Image Publisher"
                readOnly
                validate={
                  this.state.selectedTab === 0
                    ? [
                        (value: string) =>
                          value && value === '<Not Set>'
                            ? 'The value is not set in the KeyVault'
                            : undefined,
                      ]
                    : undefined
                }
              />
              <Field
                name="vmOsOffer"
                component={inputComponent}
                type="text"
                label="OS Image Offer"
                readOnly
                validate={
                  this.state.selectedTab === 0
                    ? [
                        (value: string) =>
                          value && value === '<Not Set>'
                            ? 'The value is not set in the KeyVault'
                            : undefined,
                      ]
                    : undefined
                }
              />
              <Field
                name="vmOsSku"
                component={inputComponent}
                type="text"
                label="OS Image SKU"
                readOnly
                validate={
                  this.state.selectedTab === 0
                    ? [
                        (value: string) =>
                          value && value === '<Not Set>'
                            ? 'The value is not set in the KeyVault'
                            : undefined,
                      ]
                    : undefined
                }
              />
            </Tab>
            <Tab title="Connect" eventKey="1">
              <h4>Connect existing service</h4>
              <Field
                name="ipAddress"
                component={inputComponent}
                type="text"
                placeholder="Please enter a URL"
                label="IP Address"
                validate={
                  this.state.selectedTab === 1
                    ? formValidators.required
                    : undefined
                }
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <ModalFormFooter actionResult={createResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { params, createService } = this.props;
    const { groupId } = params;
    const service: IServiceViewModel = {
      name: data.name,
      description: data.description,
      groupId,
      configData:
        this.state.selectedTab === 0
          ? {
              AdminPassword: data.sshPassword,
              AdminUserName: data.sshUser,
              PolicyId:
                this.state.selectedConfigType === ConfigurationType.POLICY
                  ? data.policyId
                  : null,
              RoleSize: data.roleSize,
              ServiceName: data.serviceName,
              TemplateName:
                this.state.selectedConfigType === ConfigurationType.TEMPLATE
                  ? data.templateName
                  : null,
              VmName: data.hostName,
              VmOsPublisher: data.vmOsPublisher,
              VmOsOffer: data.vmOsOffer,
              VmOsSku: data.vmOsSku,
            }
          : {
              ipAddress: data.ipAddress,
            },
      active: true,
      serverType: ServerType.AzureVirtualMachineService,
      dependsUpon: [],
    };

    createService(service);
  }

  private tabSelected(key: any) {
    this.setState({
      selectedTab: key as number,
    });
  }
}

const CreateVmModalForm = reduxForm<IFormData, IProps>({
  form: 'createVm',
})(CreateVmModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {
      serviceName: ownProps.data.serviceName,
      sshUser: ownProps.data.adminUsername,
      sshPassword: ownProps.data.adminPassword,
      hostName: ownProps.data.hostName,
      templateName: ownProps.data.defaultTemplate,
      roleSize: ownProps.data.defaultRoleSize,
      vmOsPublisher: ownProps.data.vmOsPublisher,
      vmOsOffer: ownProps.data.vmOsOffer,
      vmOsSku: ownProps.data.vmOsSku,
    },
    form: 'createVm',
    createResult: state.service.createResult,
    availableVmValuesResult: state.vm.availableVmValuesResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  createService: (service: IServiceViewModel) =>
    dispatch(createService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(CreateVmModalForm);
