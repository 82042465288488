import * as React from 'react';

import {
  ConfigProps,
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import {
  IActionResult,
  IAvailableAzureVmValues,
  IServiceViewModel,
} from 'types';

import { AnyAction } from 'redux';
import Dependencies from './dependencies';
import { IReduxState } from 'reducers';
import LoaderWithParams from 'components/common/loaderWithParams';
import { Modal } from 'react-bootstrap';
import ModalFormFooter from 'components/common/modalFormFooter';
import TemplateSelector from './templateSelector';
import { ThunkDispatch } from 'redux-thunk';
import VmTypeSelector from './vmTypeSelector';
import { connect } from 'react-redux';
import { formValidators } from 'utils/formValidators';
import inputComponent from 'components/common/formComponents/inputComponent';
import textAreaComponent from 'components/common/formComponents/textAreaComponent';
import { updateService } from 'slices/serviceSlice';
import PolicySelector from './policySelector';

interface IState {
  selectedTab: number;
}

interface IParams {
  service: IServiceViewModel;
}

interface IOwnProps {
  data: string;
  params: IParams;
}

interface IStateProps {
  updateResult: IActionResult<boolean>;
  availableVmValuesResult: IActionResult<IAvailableAzureVmValues>;
}

interface IDispatchProps {
  updateService: (service: IServiceViewModel) => void;
}

export interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

export interface IFormData {
  name: string;
  ipAddress: string;
  description: string;
  serviceName: string;
  sshUser: string;
  sshPassword: string;
  templateName: string;
  policyId: string;
  roleSize: string;
  hostName: string;
  vmOsPublisher: string;
  vmOsOffer: string;
  vmOsSku: string;
}

export class EditVmModal extends React.Component<
  IProps & InjectedFormProps<IFormData, IProps>,
  IState
> {
  constructor(props: IProps & InjectedFormProps<IFormData, IProps>) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
    this.save = this.save.bind(this);
    this.tabSelected = this.tabSelected.bind(this);
  }

  public render() {
    const {
      handleSubmit,
      pristine,
      updateResult,
      params,
      availableVmValuesResult,
    } = this.props;

    const { service } = params;

    return (
      <Form onSubmit={handleSubmit(this.save)}>
        <Modal.Body>
          <Field
            name="name"
            component={inputComponent}
            type="text"
            placeholder="Please enter a new name"
            label="Name"
            validate={formValidators.required}
          />
          <Field
            name="ipAddress"
            component={inputComponent}
            type="text"
            placeholder="Please enter a URL"
            label="IP Address"
            disabled
            validate={
              this.state.selectedTab === 1 ? formValidators.required : undefined
            }
          />
          <Field
            name="description"
            component={textAreaComponent}
            placeholder="Please enter a description"
            label="Description"
          />
          <Field
            name="serviceName"
            component={inputComponent}
            type="text"
            placeholder="Please enter service name"
            label="Service Name"
            disabled
            validate={
              this.state.selectedTab === 0 ? formValidators.required : undefined
            }
          />
          <Field
            name="sshUser"
            component={inputComponent}
            type="text"
            label="SSH Admin User"
            disabled
            validate={
              this.state.selectedTab === 0 ? formValidators.required : undefined
            }
            copy
          />
          <Field
            name="sshPassword"
            component={inputComponent}
            type="text"
            placeholder="Please enter a password"
            label="SSH Admin Password"
            disabled
            validate={
              this.state.selectedTab === 0
                ? [formValidators.required, formValidators.minLength(8)]
                : undefined
            }
            copy
          />
          {service.configData.PolicyId ? (
            <LoaderWithParams
              component={PolicySelector}
              actionresult={availableVmValuesResult}
              params={{
                isRequired: this.state.selectedTab === 0,
                disabled: true,
              }}
            />
          ) : null}
          {service.configData.TemplateName ? (
            <LoaderWithParams
              component={TemplateSelector}
              actionresult={availableVmValuesResult}
              params={{
                isRequired: this.state.selectedTab === 0,
                disabled: true,
              }}
            />
          ) : null}
          <LoaderWithParams
            component={VmTypeSelector}
            actionresult={availableVmValuesResult}
            params={{ isRequired: this.state.selectedTab === 0 }}
          />
          <Field
            name="hostName"
            component={inputComponent}
            type="text"
            placeholder="Please enter a host name"
            label="Host Name"
            disabled
            validate={
              this.state.selectedTab === 0 ? formValidators.required : undefined
            }
          />
          <Field
            name="vmOsPublisher"
            component={inputComponent}
            type="text"
            label="OS Image Publisher"
            readOnly
          />
          <Field
            name="vmOsOffer"
            component={inputComponent}
            type="text"
            label="OS Image Offer"
            readOnly
          />
          <Field
            name="vmOsSku"
            component={inputComponent}
            type="text"
            label="OS Image SKU"
            readOnly
          />
          <Dependencies
            dependentUpon={service.dependentUpon!}
            dependsUpon={service.dependsUpon}
          />
        </Modal.Body>
        <ModalFormFooter actionResult={updateResult} disabled={pristine} />
      </Form>
    );
  }

  private save(data: IFormData) {
    const { updateService, params } = this.props;
    const { service } = params;
    const updatedService = Object.assign({}, service, {
      name: data.name,
      description: data.description,
      templateName: data.templateName,
      configData: {
        RoleSize: data.roleSize,
      },
    });

    updateService(updatedService);
  }

  private tabSelected(key: any) {
    this.setState({
      selectedTab: key as number,
    });
  }
}

const EditVmModalForm = reduxForm<IFormData, IProps>({
  form: 'editVm',
})(EditVmModal);

const mapStateToProps = (
  state: IReduxState,
  ownProps: IOwnProps
): IStateProps & ConfigProps<IFormData, IProps> => {
  return {
    initialValues: {
      name: ownProps.params.service.name,
      description: ownProps.params.service.description,
      ipAddress: ownProps.params.service.url,
      serviceName: ownProps.params.service.configData.ServiceName,
      sshUser: ownProps.params.service.configData.AdminUserName,
      sshPassword: ownProps.params.service.configData.AdminPassword,
      hostName: ownProps.params.service.configData.VmName,
      templateName: ownProps.params.service.configData.TemplateName,
      policyId: ownProps.params.service.configData.PolicyId,
      roleSize: ownProps.data,
      vmOsPublisher: ownProps.params.service.configData.VmOsPublisher,
      vmOsOffer: ownProps.params.service.configData.VmOsOffer,
      vmOsSku: ownProps.params.service.configData.VmOsSku,
    },
    form: 'editVm',
    updateResult: state.service.updateResult,
    availableVmValuesResult: state.vm.availableVmValuesResult,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IReduxState, null, AnyAction>
): IDispatchProps => ({
  updateService: (service: IServiceViewModel) =>
    dispatch(updateService(service)),
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(EditVmModalForm);
